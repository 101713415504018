import { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Space, List, Typography, Spin, Tabs, Empty, Divider, Avatar, Input, Comment, Rate, Form, Button, message } from 'antd';
import {
  Navigation as NavigationIcon,
  PhoneCall as PhoneCallIcon,
  Home as HomeIcon,
  MessageSquare as MessageSquareIcon,
  Layers as LayersIcon,
  Box as BoxIcon,
  Gift as GiftIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  Info as InfoIcon,
  Star as StarIcon,
} from 'react-feather';

import { Api, Locale } from '../../services';
import { Slider, Banner, ProfileHeader, Container, ProductCard, ProfileCardCircle } from '../../components';
import { CDN_URL } from '../../helpers';
import { colors, spaces, borders, sizes } from '../../style';
import { isMobile } from '../../utils';
import { useInfiniteProducts } from '../../hooks';
import { ProfileProducts } from '../../components/profile';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

const api = new Api();

const ProfileView = () => {
  const navigate = useNavigate();

  const { uuid } = useParams();
  const [search] = useSearchParams();
  const [reviewForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('2');

  const { app } = useSelector((state) => state);

  useEffect(() => {
    setLoading(true);
    api
      .getProfileView(uuid)
      .then((response) => {
        setData(response.data);
        setUser(response.data?.user);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [uuid]);

  useEffect(() => {
    let new_tab = Object.fromEntries(search)?.tab;
    new_tab == undefined ? setCurrentTab('2') : setCurrentTab(new_tab);
  }, [search, navigate]);

  return (
    <>
      <Helmet>
        <title>{`${data.user?.trans?.public?.name}`} | Happy Shop</title>
        <meta name="description" content={data.user?.trans?.description} />
        <meta property="og:title" content={data.user?.trans?.public?.name} />
        <meta property="og:description" content={data.user?.trans?.description} />
        <meta property="og:image" content={CDN_URL + data.user?.img} />
      </Helmet>

      <Spin tip={Locale['loading']} spinning={loading}>
        <Space direction="vertical" size={sizes.xxl} style={{ display: 'flex' }}>
          <ProfileHeader data={user} />
          <Container xs={22}>
            <ProfileProducts user_uuid={uuid} />
          </Container>
        </Space>
      </Spin>
    </>
  );
};

export default ProfileView;
