export const API_URL = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_API_LOCAL : process.env.REACT_APP_ENV == 'development' ? process.env.REACT_APP_API_DEVELOPMENT : process.env.REACT_APP_API_PRODUCTION;
export const CDN_URL = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_CDN_LOCAL : process.env.REACT_APP_ENV == 'development' ? process.env.REACT_APP_CDN_DEVELOPMENT : process.env.REACT_APP_CDN_PRODUCTION;

export const FB_ID = process.env.REACT_APP_FACEBOOK_ID;
export const FB_SECRET = process.env.REACT_APP_FACEBOOK_SECRET;

export const DEFAULT_LOCALE = process.env.REACT_APP_LOCALE;
export const CURRENCY = '₾';
// export const API_URL = 'http://happy-shop-api.test/api/v1'
// export const CDN_URL = 'http://happy-shop-api.test/uploads/images/'
