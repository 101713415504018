import React, { useEffect, useRef } from 'react';
import { Row, Col, Space, List, Typography, Card, Select, Empty, Divider, Avatar, Input, Form, Button, message } from 'antd';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { useInfiniteProducts, useProfileCategories } from '../../hooks';
import ProductCard from '../product-card';
import { Locale } from '../../services';
import { spaces } from '../../style';
import { Radio } from 'antd';
import { debounce } from 'lodash';
import { Spin } from 'antd';
import { isMobile } from '../../utils';

const { Title, Text, Link } = Typography;
const { Option } = Select;
const { Search } = Input;

export const ProfileProducts = ({ user_uuid, hasFilterSidebar = false }) => {
  const { productsList, isLoadingProducts, hasMoreProducts, loadMoreProducts, fetchProductsList, handleProductFilterChange, productFilters } = useInfiniteProducts({
    user_uuid,
  });

  const { categoriesList, isLoadingCategories, fetchCategoriesList } = useProfileCategories(user_uuid);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreProducts && !isLoadingProducts) {
          loadMoreProducts();
        }
      },
      { threshold: 0.1 },
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [hasMoreProducts, isLoadingProducts, loadMoreProducts]);

  useEffect(() => {
    fetchCategoriesList();
  }, []);

  useEffect(() => {
    fetchProductsList();
  }, []);

  return (
    <Row gutter={[64, 32]}>
      {!isMobile() && (
        <Col xs={24} md={24} lg={24} xl={6} xxl={6}>
          <Card
            title={
              <Title className="uppercase" level={5} style={{ marginBottom: 0 }}>
                {Locale['filter']}
              </Title>
            }
            extra={<FilterIcon size={18} style={{ display: 'flex' }} />}
          >
            <Space size={spaces.xs} wrap>
              <Radio.Group
                onChange={(e) => {
                  handleProductFilterChange('category', e.target.value);
                }}
                value={productFilters.category || 0}
              >
                <Space direction="vertical">
                  <Radio value={0}>{Locale['All']}</Radio>
                  {categoriesList?.map((item, key) => (
                    <Radio value={item.id} key={key}>
                      {item.trans?.title}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
              {/* <Divider /> */}
            </Space>
          </Card>
        </Col>
      )}
      <Col xs={24} md={24} lg={24} xl={18} xxl={18}>
        <Space direction={'vertical'} size={spaces.xl}>
          <Row gutter={[16, 16]}>
            <Col xs={16} sm={12} xl={20}>
              <Input
                loading={isLoadingProducts}
                placeholder={Locale['Enter a search word']}
                suffix={<SearchIcon size={16} />}
                size="large"
                onChange={debounce((e) => {
                  handleProductFilterChange('search', e.target.value);
                }, 1000)}
              />
            </Col>
            <Col xs={8} sm={12} xl={4}>
              <Select
                // bordered={false}
                defaultValue="new_date"
                onChange={(e) => {
                  console.log(e);
                  // setParams((prevState) => ({ ...prevState, sort: e }));
                  handleProductFilterChange('sort', e);
                }}
                size="large"
                style={{ width: '100%' }}
              >
                <Option value="new_date">{Locale['the newest']}</Option>
                <Option value="old_date">{Locale['the oldest']}</Option>
                <Option value="low_price">{Locale['decreasing in price']}</Option>
                <Option value="high_price">{Locale['As the price increases']}</Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            {productsList.map((item, key) => (
              <Col key={key} xs={12} md={12} lg={8} xxl={6}>
                <ProductCard objectFit="contain" item={item} />
              </Col>
            ))}
          </Row>
        </Space>
        <div ref={observerTarget}>
          {isLoadingProducts && (
            <Space style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </Space>
          )}
        </div>
      </Col>
    </Row>
  );
};
