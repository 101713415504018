import axios from 'axios';
import { API_URL, DEFAULT_LOCALE } from '../helpers';

export default class Api {
  /* A function that is called when a new instance of the class is created. */
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = API_URL;
    this.locale = DEFAULT_LOCALE;
  }

  /**
   * Builds hash utility
   * @returns
   */
  static build() {
    return new this();
  }

  /* A function that is called when a new instance of the class is created. */
  init = () => {
    this.api_token = JSON.parse(localStorage.getItem('state'))?.user?.token;
    this.locale = localStorage.getItem('locale');

    let headers = {
      Accept: 'application/json',
    };

    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
      withCredentials: true,
    });

    this.client.defaults.params = { lang: this.locale };

    return this.client;
  };

  // pages
  getApp = () => this.init().get('/app');
  getMain = () => this.init().get('/main');
  getFaq = () => this.init().get('/faqs');
  getSearch = (params) => this.init().get('/search', { params: { ...params } });

  getPages = () => this.init().get('/pages');
  getPage = (id) => this.init().get(`/page/${id}`);

  // products
  getProducts = (params) => this.init().get('/products', { params: { ...params } });
  getProductsPremium = (params) => this.init().get('/products/premium', { params: { ...params } });
  getProductDetails = (id) => this.init().get(`/products/view/${id}`);
  getProductReviews = (id) => this.init().get(`/products/reviews/${id}`);
  addProductReviews = (product_id, params) => this.init().get(`/products/reviews/add/${product_id}`, { params: { ...params } });
  addProductBid = (product_id, params) => this.init().get(`/products/bids/add/${product_id}`, { params: { ...params } });

  // categories
  getCategory = (id) => this.init().get(`/categories/view/${id}`);
  getCategories = (params) => this.init().get('/categories', { params: { ...params } });

  // profile
  getProfiles = (params) => this.init().get(`/profiles`, { params: { ...params } });
  getProfileView = (uuid) => this.init().get(`/profiles/view/${uuid}`);
  getProfileCategories = (uuid, params) => this.init().get(`/profiles/categories/${uuid}`, { params: { ...params } });

  // basket
  getBasket = (product_ids) => this.init().get(`/basket`, { params: { product_ids: product_ids } });

  // store
  getStorePriceExpanse = (price) => this.init().get(`/store/price-expanse`, { params: { price: price } });

  // auth
  authSignIn = (params) => this.init().post(`/auth/sign-in`, { ...params });
  authVerifyCode = (params) => this.init().post(`/auth/send`, { ...params });

  authSignInByEmail = (params) => this.init().post(`/auth/sign-in-by-email`, { ...params });
  authVerifyEmailCode = (params) => this.init().post(`/auth/send-email`, { ...params });

  // authSignInByFb = (params) => this.init().post(`/auth/sign-in-by-fb`, { ...params });

  // user
  getUser = () => this.init().get(`/user`);
  updateUser = (params) => this.init().post(`/user/update`, { ...params });
  updateCompany = (params) => this.init().post(`/user/update-company`, { ...params });

  getUserCoins = () => this.init().get(`/user/coins`);

  getUserProducts = () => this.init().get(`/user/products`);
  createUserProduct = () => this.init().post(`/user/products/create`);
  eidtUserProduct = (id) => this.init().post(`/user/products/edit/${id}`);
  updateUserProduct = (id, params) => this.init().post(`/user/products/update/${id}`, { ...params });
  checkoutUserProductGrade = (params) => this.init().post(`/user/checkout/grades/redirect?`, { ...params });
  deleteUserProduct = (id) => this.init().post(`/user/products/delete/${id}`);
  deleteUserProductGalleryItem = (id, gallery_item_id) => this.init().post(`/user/products/gallery/delete/${id}/${gallery_item_id}`);
  updateUserProductGallerySort = (id, params) => this.init().post(`/user/products/gallery/sort/${id}`, { ...params });

  getUserOrders = () => this.init().get(`/user/orders`);
  getUserSales = () => this.init().get(`/user/sales`);

  getUserWishlists = () => this.init().get(`/user/wishlists`);
  addUserWishlist = (id) => this.init().post(`/user/wishlists/add/${id}`);

  getUserFollow = () => this.init().get(`/user/follow`);
  addUserFollow = (user_uuid) => this.init().post(`/user/follow/add/${user_uuid}`);
  removeUserFollow = (user_uuid) => this.init().post(`/user/follow/remove/${user_uuid}`);

  checkoutProducts = (params) => this.init().post(`/user/checkout/products`, { ...params });
  checkoutProductsRedirect = (params) => this.init().post(`/user/checkout/products/redirect`, { ...params });

  checkoutGifts = (params) => this.init().get(`/user/checkout/gifts`, { params: { ...params } });
  checkoutGiftsRedirect = (params) => this.init().post(`/user/checkout/gifts/redirect`, { ...params });

  checkout = (target = 'Product', params) => this.init().post(`/user/checkout/${target}`, { ...params });
  checkoutRedirect = (target = 'Product', params) => this.init().post(`/user/checkout/${target}`, { ...params });

  // tools
  getSettings = () => this.init().get('/settings');
  getEnums = () => this.init().get('/tools/enums');
}
