import { useCallback, useState } from 'react';
import { Api } from '../../services';

const useProfileCategories = (user_uuid) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const fetchCategoriesList = useCallback(async () => {
    if (isLoadingCategories) return;

    console.log(user_uuid)

    try {
      setIsLoadingCategories(true);
      const response = await Api.build().getProfileCategories(user_uuid, {});
      setCategoriesList(response.data?.categories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setIsLoadingCategories(false);
    }
  }, [isLoadingCategories]);

  return { categoriesList, isLoadingCategories, fetchCategoriesList };
};

export default useProfileCategories;
