import { useCallback, useState, useEffect, useRef } from 'react';
import { Api } from '../../services';
import { debounce } from 'lodash';

const FILTER_FIELDS = ['search', 'title', 'type', 'condition', 'grade', 'highlight', 'status', 'category', 'sort', 'user_uuid'];
const DEBOUNCE_DELAY = 500;

const useInfiniteProducts = (initialFilters = {}) => {
  const [productsList, setProductsList] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productFilters, setProductFilters] = useState(initialFilters);

  const isInitialMount = useRef(true);

  const getFilterParams = useCallback(() => {
    const params = { page: currentPage };

    FILTER_FIELDS.forEach((field) => {
      if (productFilters[field]) {
        params[field] = productFilters[field];
      }
    });

    return params;
  }, [currentPage, productFilters]);

  const fetchProductsList = useCallback(async () => {
    if (isLoadingProducts) return;

    try {
      setIsLoadingProducts(true);
      const response = await Api.build().getProducts(getFilterParams());
      const { data: newProducts, current_page, last_page } = response.data.products;

      setProductsList(currentPage === 1 ? newProducts : (prev) => [...prev, ...newProducts]);
      setHasMoreProducts(current_page < last_page);
    } catch (error) {
      console.error('Error fetching products:', error);
      setHasMoreProducts(false);
    } finally {
      setIsLoadingProducts(false);
    }
  }, [currentPage, getFilterParams, isLoadingProducts]);

  // Effect to handle filter changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const timer = setTimeout(() => {
      fetchProductsList();
    }, 0);

    return () => clearTimeout(timer);
  }, [productFilters, currentPage]);

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
    setHasMoreProducts(true);
    setProductsList([]); // Clear existing products when filters change
  }, []);

  const loadMoreProducts = useCallback(() => {
    if (!isLoadingProducts && hasMoreProducts) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [isLoadingProducts, hasMoreProducts]);

  const handleProductFilterChange = useCallback(
    (filterType, value) => {
      resetPagination();
      setProductFilters((prev) => ({
        ...prev,
        [filterType]: value,
      }));
    },
    [resetPagination],
  );

  const handleProductSort = useCallback(
    (sortType) => {
      resetPagination();
      setProductFilters((prev) => ({
        ...prev,
        sort: sortType,
      }));
    },
    [resetPagination],
  );

  return {
    productsList,
    isLoadingProducts,
    hasMoreProducts,
    loadMoreProducts,
    productFilters,
    handleProductFilterChange,
    handleProductSort,
    fetchProductsList,
  };
};

export default useInfiniteProducts;
